
<template>
  <div id="wrap" :style="{ height: screenHeight + 'px' }" v-show='this.isPc'>
    
    <div id="main" :style="{ top: nowTop + 'px' }">
      <!-- <ul id="pageUl" type="circle">
        <li id="pageUlLi1" class="pageUlLi" :class="{'active': curIndex == 1}">&nbsp;</li>
        <li id="pageUlLi2" class="pageUlLi" :class="{'active': curIndex == 2}">&nbsp;</li>
        <li id="pageUlLi3" class="pageUlLi" :class="{'active': curIndex == 3}">&nbsp;</li>
        <li id="pageUlLi4" class="pageUlLi" :class="{'active': curIndex == 4}">&nbsp;</li>
        <li id="pageUlLi5" class="pageUlLi" :class="{'active': curIndex == 5}">&nbsp;</li>
      </ul> -->
      <div class='nav'>
        <!-- @click='toPage(1)' -->
        <img class='logo' @click='toPage(1)' src="./assets/logo.png"  alt="">
        <img class='nav1' @click='toPage(1)' src="./assets/top/about-us.png" alt="">
        <img class='nav2' @click='toPage(2)' src="./assets/top/glsd-app.png" alt="">
        <img class='nav3' @click='toPage(3)' src="./assets/top/wine.png" alt="">
        <img class='nav4' @click='toPage(4)' src="./assets/top/concat.png" alt="">
        <img class='nav5' @click='toPage(5)' src="./assets/top/comperate.png" alt="">
        <div class="nav6" style="color: white;line-height: 100px;font-size: 18px; cursor: pointer;" @click='openIframe()'>收费资讯</div>
      </div>
      <div id="page0" v-if='showVideo'>
         <!-- <video
            poster="./assets/poster.png"
            autoplay
            muted
            loop
            v-if='showVideo'
          >
            <source  src="./assets/bg-video.mp4">
          </video> -->
          <div class="page0-img">
            <img class='page0-gif' :src="bgVideo" alt="">
            <img class='page0-bg' src="./assets/page0-bg.png" alt="">

          </div>
        <img class='close' v-if='showVideo' @click='showVideo=false,curIndex=1' src="./assets/close-1.png" alt="">
      </div>
      <div id="page1" class="page">
        <div class="person" >
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
            <img class='page11' v-if='curIndex==1' src="./assets/page1/1-1.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
            <img class='page12' v-if='curIndex==1' src="./assets/page1/1-2.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
            <img class='page13' v-if='curIndex==1' src="./assets/page1/1-3.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
            <img class='page14' v-if='curIndex==1' src="./assets/page1/1-4.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
            <img class='page15' v-if='curIndex==1' src="./assets/page1/1-5.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
            <img class='page16' v-if='curIndex==1' src="./assets/page1/1-6.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
            <img class='page17' v-if='curIndex==1' src="./assets/page1/1-7.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
            <img class='page18' v-if='curIndex==1' src="./assets/page1/1-8.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
            <img class='page19' v-if='curIndex==1' src="./assets/page1/1-9.png" alt="">
          </transition>
          

        </div>

      </div>
      <div id="page2" class="page">
        <div class="person">
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page21' v-if='curIndex==2' src="./assets/page2/2-1.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page22' v-if='curIndex==2' src="./assets/page2/2-2.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page23' v-if='curIndex==2' src="./assets/page2/2-3.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page24' v-if='curIndex==2' src="./assets/page2/2-4.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn"  leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page25' v-if='curIndex==2' src="./assets/page2/2-5.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page26' v-if='curIndex==2' src="./assets/page2/2-6.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page27' v-if='curIndex==2' src="./assets/page2/2-7.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page28' v-if='curIndex==2' src="./assets/page2/2-8.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page29' v-if='curIndex==2' src="./assets/page2/2-9.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page210' v-if='curIndex==2' src="./assets/page2/2-10.png" alt="">
          </transition>
          <!-- <img class='page21' src="./assets/page2/2-1.png" alt="">
          <img class='page22' src="./assets/page2/2-2.png" alt="">
          <img class='page23' src="./assets/page2/2-3.png" alt="">
          <img class='page24' src="./assets/page2/2-4.png" alt="">
          <img class='page25' src="./assets/page2/2-5.png" alt="">
          <img class='page26' src="./assets/page2/2-6.png" alt="">
          <img class='page27' src="./assets/page2/2-7.png" alt="">
          <img class='page28' src="./assets/page2/2-8.png" alt="">
          <img class='page29' src="./assets/page2/2-9.png" alt="">
          <img class='page210' src="./assets/page2/2-10.png" alt=""> -->
        </div>

      </div>
      <div id="page3" class="page">
        <div class="person">
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page31' v-if='curIndex==3' src="./assets/page3/3-1.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page32' v-if='curIndex==3' src="./assets/page3/3-2.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInDown" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page33' v-if='curIndex==3' src="./assets/page3/3-3.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page34' v-if='curIndex==3' src="./assets/page3/3-4.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page35' v-if='curIndex==3' src="./assets/page3/3-7.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page36' v-if='curIndex==3' src="./assets/page3/3-6.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page37' v-if='curIndex==3' src="./assets/page3/3-5.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page38' v-if='curIndex==3' src="./assets/page3/3-8.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page39' v-if='curIndex==3' src="./assets/page3/3-9.png" alt="">
          </transition>
          <!-- <img class='page31' src="./assets/page3/3-1.png" alt="">
          <img class='page32' src="./assets/page3/3-2.png" alt="">
          <img class='page33' src="./assets/page3/3-3.png" alt="">
          <img class='page34' src="./assets/page3/3-4.png" alt="">
          <img class='page35' src="./assets/page3/3-5.png" alt="">
          <img class='page36' src="./assets/page3/3-6.png" alt="">
          <img class='page37' src="./assets/page3/3-7.png" alt="">
          <img class='page38' src="./assets/page3/3-8.png" alt="">
          <img class='page39' src="./assets/page3/3-9.png" alt=""> -->
        </div>
        
      </div>
      <div  id="page4" class="page">
        
        <div class='person'>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page45' v-if='curIndex==4' src="./assets/page4/4-5.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page41' v-if='curIndex==4' src="./assets/page4/4-1.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInLeft" leave-active-class="animate__animated animate__fast animate__bounceOutLeft">
              <img class='page42' v-if='curIndex==4' src="./assets/page4/4-2.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page43' v-if='curIndex==4' src="./assets/page4/4-3.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page44' v-if='curIndex==4' src="./assets/page4/4-4.png" alt="">
          </transition>
          <!-- <img class='page45' src="./assets/page4/4-5.png" alt="">
          <img class='page41' src="./assets/page4/4-1.png" alt="">
          <img class='page42' src="./assets/page4/4-2.png" alt="">
          <img class='page43' src="./assets/page4/4-3.png" alt="">
          <img class='page44' src="./assets/page4/4-4.png" alt=""> -->
        </div>
      </div>
      <div  id="page5" class="page">
        <div class="person">
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page51' v-if='curIndex==5' src="./assets/page5/5-1.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page52' v-if='curIndex==5' src="./assets/page5/5-2.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page53' v-if='curIndex==5' src="./assets/page5/5-3.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page54' v-if='curIndex==5' src="./assets/page5/5-4.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceInRight" leave-active-class="animate__animated animate__fast animate__bounceOutRight">
              <img class='page55' v-if='curIndex==5' src="./assets/page5/5-5.png" alt="">
          </transition>
          <transition enter-active-class="animate__animated animate__fast animate__bounceIn" leave-active-class="animate__animated animate__fast animate__bounceOut">
              <img class='page56' v-if='curIndex==5' src="./assets/page5/5-6.png" alt="">
          </transition>
          
          <!-- <img class='page51' src="./assets/page5/5-1.png" alt="">
          <img class='page52' src="./assets/page5/5-2.png" alt="">
          <img class='page53' src="./assets/page5/5-3.png" alt="">
          <img class='page54' src="./assets/page5/5-4.png" alt="">
          <img class='page55' src="./assets/page5/5-5.png" alt="">
          <img class='page56' src="./assets/page5/5-6.png" alt=""> -->
          
        </div>
        <div class="bot">
          <div>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">2018©公路商店 www.ontheroadstore.com | 京ICP备14015381号-1丨</a><span @click='showLicense=true'>新出发京零字第朝190306号</span>丨北京灿烂千阳文化有限公司
          </div>
          <div>
            中国互联网举报中心 | 违法和不良信息举报：010-83434212
          </div>
        </div>
       
      </div>
       

    </div>
    <div class="license" v-show='showLicense'>
      <img class='license-img' src="./assets/license1.jpeg" alt="">
      <img class='license-img' src="./assets/license2.jpeg" alt="">

      <img class='close'  @click='showLicense=false' src="./assets/close-1.png" alt="">
    </div>
  </div>
  <div style="width: 100vw;height: 100vh;position: absolute;top: 0;left: 0;z-index:9999;" v-if="showIframe">
    <img class='close-iframe'  @click='showIframe=false' src="./assets/close-1.png" alt="">
    <iframe  :width="screenWeight+'px'" :height="screenHeight+'px'" src="https://ontheroadstore.website6.idata5.com"></iframe>
  </div>
  <div id='mobile' v-show='!isPc'>
    <div class="btn1"></div>
    <div class="btn2" @click='jump2'></div>
    <div class="btn3" @click='jump3'></div>

  </div>
</template>
 
<script>
  import bgVideo from './assets/dsj.gif'
  import logoGif from './assets/logo.gif'

  export default {
    data(){
      return{
        screenWeight: 0,    // 屏幕宽度
        screenHeight: 0,    // 屏幕高度
        index: 1,        // 用于判断翻页
        curIndex: 0,      // 当前页的index
        startTime: 0,      // 翻屏起始时间 
        endTime: 0,       // 上一次翻屏结束时间
        nowTop: 0,       // 翻屏后top的位置
        pageNum: 0,       // 一共有多少页
        main: Object,
        obj: Object,
        bgVideo: bgVideo,
        logoGif: logoGif,
        showVideo: true,
        showLicense: false,
        isPc: true,
        showIframe: false
      }
    },
    created() {
      const resizeEvt =
        'orientationchange' in window ? 'orientationchange' : 'resize'
      const resetRecalc = () => {
        const __clientWidth =
          document.documentElement.clientWidth || document.body.clientWidth
        if (__clientWidth < 1024) {
          this.isPc = false
         
        } else {
          this.isPc = true
        }
      }
      window.addEventListener(resizeEvt, resetRecalc.bind(this), false)
      window.addEventListener('load', resetRecalc.bind(this), false)
    },
    mounted(){
      // this.curIndex=1
      this.screenWeight = document.documentElement.clientWidth;
      this.screenHeight = document.documentElement.clientHeight;
      this.main = document.getElementById("main");
      this.obj = document.getElementsByTagName("div");
      for (let i = 0; i < this.obj.length; i++) {
        if (this.obj[i].className == 'page') {
          this.obj[i].style.height = this.screenHeight + "px";
        }
      }
      this.pageNum = document.querySelectorAll(".page").length;
 
      // 浏览器兼容   
      if ((navigator.userAgent.toLowerCase().indexOf("firefox") != -1)) {
        document.addEventListener("DOMMouseScroll", this.scrollFun, false);
      } else if (document.addEventListener) {
        document.addEventListener("mousewheel", this.scrollFun, false);
      } else if (document.attachEvent) {
        document.attachEvent("onmousewheel", this.scrollFun);
      } else {
        document.onmousewheel = this.scrollFun;
      }
    },
    methods:{
      scrollFun(event) {
        this.startTime = new Date().getTime();
        // mousewheel事件中的 “event.wheelDelta” 属性值：返回的如果是正值说明滚轮是向上滚动
        // DOMMouseScroll事件中的 “event.detail” 属性值：返回的如果是负值说明滚轮是向上滚动
        let delta = event.detail || (-event.wheelDelta);
        // 如果当前滚动开始时间和上次滚动结束时间的差值小于1.5s，则不执行翻页动作，这样做是为了实现类似节流的效果
        if ((this.startTime - this.endTime) > 1500) {
          if (delta > 0 && parseInt(this.main.offsetTop) >= -(this.screenHeight * (this.pageNum - 2))) {
            // 向下滚动
            // this.index++;
            // this.toPage(this.index);
            this.toPage(this.curIndex+1);
          }else if (delta < 0 && parseInt(this.main.offsetTop) < 0) {
            // 向上滚动
            // if(this.curIndex==2&&this.index<=1){
            //   return
            // }
            // this.index--;
            // this.toPage(this.index);
            this.toPage(this.curIndex-1);
          }
          // 本次翻页结束，记录结束时间，用于下次判断
          this.endTime = new Date().getTime();
        }
      },
      // 翻页
      toPage(index) {
          // console.log(this.curIndex,index);
          // if(this.curIndex>=5){
          //   this.nowTop=5*this.screenHeight+110
          //   return
          // }
        if (index != this.curIndex) {
          let delta = index - this.curIndex;
          // if(this.nowTop - delta * this.screenHeight>=0){
          //   this.nowTop=0
          // }else{
          //   this.nowTop = this.nowTop - delta * this.screenHeight
          //   this.curIndex = index;
          // }
          this.nowTop = this.nowTop - delta * this.screenHeight
          this.curIndex = index;
          
        }
      },
      jump1(){
        window.location.href=''
      },
      jump2(){
        window.location.href='https://a.app.qq.com/o/simple.jsp?pkgname=com.ontheroadstore.hs'
      },
      jump3(){
        window.location.href='https://a.app.qq.com/o/simple.jsp?pkgname=com.ontheroadstore.hs'
      },
      openIframe(){
        this.showIframe=true
      }
    }
  }
</script>
<style>
.close-iframe{
  position: absolute;
  top: 100px;
  right: 50px;
  cursor: pointer;
}
.license{
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;

}
.license-img{
  margin: 0 auto;
  width: 500px;
  display: block;
  object-fit: fill;
}
  html, body {
    height: 100%;
  }
 
  body, ul, li, a, p, div {
    /*慎删*/
    padding: 0px;
    margin: 0px;
  }
  #mobile{
    width: 100vw;
    height: 193.16vw;
    background: url(./assets/m-bg.png)  no-repeat top center;
    background-size:cover;
    position: relative;

  }
  #mobile>div{
    position: absolute;
    width: 50vw;
    height: 30vw;
    z-index: 22;
  }
  .btn1{
    top: 77vw;
    left: 10vw;
  }
  .btn2{
    top: 130vw;
    left: 0vw;
  }
  .btn3{
    top: 130vw;
    right: 0vw;
  }
 
  #wrap {
    overflow: hidden;
    width: 100%;
    background: url(./assets/bg.png) no-repeat center center;

  }
 
  #main {
    position: relative;
    transition:top 1.5s;
    /* display: none; */
  }
 
  .page {
    /*谨删*/
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    /* background: url(./assets/bg.png) no-repeat center center; */
    position: relative;
  }
 
  #pageUl {
    position: fixed;
    right: 10px;
    bottom: 50%;
  }
 
  .active{
    color: red;
  }
   #page0{
     position: fixed;
     top: 0;
     left: 0;
     bottom: 0;

     margin: auto;

     z-index: 100;
     /* background: url(./assets/page0-bg.png) no-repeat center center; */
     width: 100vw;
     height: 100vh;
     background: #fff;
     background-size: cover;
   }
   .page0-bg{
      position: fixed;
      width: 100vw;
      height: 56.25vw;
      top: 0;
      left: 0;
      z-index: 98;
      object-fit: cover;
   }
   .page0-gif{
    /* width: 100vw;
    height: 100vh; */
    object-fit: cover;
    position: absolute;
    left: 36vw;
    width: 23vw;
    height: 17.2vw;
    /* right: 0;
    bottom: 0; */
    top: 15.8vw;
    z-index: 99;
    /* margin: auto; */
    
  }
  .nav{
    width: 100vw;
    background: url(./assets/top/nav-bg.png) no-repeat center center;
    height: 139px;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    padding-right: 200px;
    box-sizing: border-box;
    z-index: 99;
  }
  
  .nav img{
    height: 50px;
    position: relative;
    margin: 0 36px;
  }
  .nav .logo{
    position: absolute;
    left: 100px;
    top: 24px;
  }
  .nav .nav5{
    height: 75px;
  }
  .nav1{
    width: 113px;
    top: 33px;
  }
  .nav2{
    width: 113px;
    top: 30px;
  }
  .nav3{
    width: 112px;
    top: 32px;
  }
  .nav .nav4{
    width: 163px;
    height: 75px;
    top: 21px;

  }
  .nav .nav5{
    width: 163px;
    height: 75px;
    top: 24px;
  }
  video {
    position: absolute;
    /* Vertical and Horizontal center*/
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }
  .close{
    position: absolute;
    z-index: 999;
    top: 50px;
    right: 50px;
    width: 50px;
    height: 68px;
  }
  
  .page0-logo{
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    margin: auto;
  }
  .page >img{
    position: absolute;
  }
  .person>img{
    position: absolute;
  }
  #page1 .person{
    position: absolute;
    width: 29.2vw;
    height: 37.8vw;
    left: 0px;
    right: 25vw;
    bottom: 0vw;
    top: 100px;
    margin: auto;
    /* transform: scale(.9); */

  }
  .page11{
    left: 12.5vw;
    top: 11.5vw;
    z-index: 2;
    width: 32.15vw;
    height: 2.4vw;

  }
  .page12{
    left: 11.5vw;
    top: 27.5vw;
    z-index: 2;
    width: 34vw;
    height: 2.85vw;
    
  }
  .page13{
    left: 15vw;
    top: 16.5vw;
    z-index: 2;
    width: 27.75vw;
    height: 1.95vw;
    
  }
  .page14{
    left: 17.5vw;
    top: -.5vw;
    z-index: 2;
    width: 21.4vw;
    height: 5.75vw;
  }
  .page15{
    left: 14vw;
    top: 19vw;
    z-index: 2;
    width: 28.1vw;
    height: 2.75vw;
  }
  .page16{
    left: 19vw;
    top: 23vw;
    z-index: 2;
    width: 20.45vw;
    height: 2.35vw;
    
  }
  .page17{
    left: 16vw;
    top: 31vw;
    z-index: 2;
    
  }
  .page18{
    /* left: 15vw;
    top: 150px; */
    width: inherit;
    height: inherit;
    
  }
  .page19{
    left: 29vw;
    top: 8vw;
    width: 20.5vw;
    height: 2.3vw;
  }
  #page2 .person{
    position: absolute;
    width: 30.1vw;
    height: 35.8vw;
    left: 0px;
    right: 20vw;
    bottom: 0vw;
    top: 100px;
    margin: auto;
  }
  .page21{
    left: 21vw;
    top: 12.5vw;
    z-index: 2;
    width: 29.7vw;
    height: 6.75vw;

  }
  .page22{
    left: 10vw;
    top: 12.5vw;
    z-index: 2;
    width: 10.9vw;
    height: 10.4vw;
    
  }
  .page23{
    left: -5vw;
    top: -2vw;
    z-index: 2;
    width: 14vw;
    height: 20.15vw;
    
  }
  .page24{
    left: 21.5vw;
    top: 18.5vw;
    z-index: 2;
    width: 16.85vw;
    height: 2.85vw;
  }
  .page25{
    /* left: 500px;
    top: 150px; */
    width: inherit;
    height: inherit;
    
  }
  .page26{
    left: 24vw;
    top: 7.5vw;
    z-index: 2;
    width: 26.35vw;
    height: 5.5vw;
  }
  .page27{
    left: 9vw;
    top: 29vw;
    z-index: 2;
    width: 26.4vw;
    height: 5.55vw;
  }
  .page28{
    left: 14vw;
    top: 1.75vw;
    width: 32.2vw;
    height: 4.05vw;
  }
  .page29{
    left: 23vw;
    top: 22.5vw;
    z-index: 2;
    width: 27.5vw;
    height: 3.9vw;
  }
  .page210{
    left: 5.5vw;
    top: 4.5vw;
    width: 48.7vw;
    height: 20.7vw;
  }
  #page3 .person{
    position: absolute;
    width: 26.1vw;
    height: 37.2vw;
    left: 0px;
    right: 14vw;
    bottom: 0px;
    top: 210px;
    margin: auto;
    transform: scale(.9);
  }
  .page31{
    left: 14vw;
    top: 22vw;
    z-index: 2;
    width: 20.35vw;
    height: 15vw;
  }
  .page32{
    left: 14.5vw;
    top: 22vw;
    z-index: 3;
    width: 1.7vw;
    height: 1.7vw;
  }
  .page33{
    left: 8vw;
    top: -6.5vw;
    z-index: 3;
    width: 16.5vw;
    height: 10.9vw;
  }
  .page34{
    left: 11vw;
    top: -5.5vw;
    z-index: 2;
    width: 27.3vw;
    height: 18.55vw;
  }
  .page35{
    left: 14.5vw;
    top: 9vw;
    z-index: 3;
    width: 22.5vw;
    height: 2.85vw;
  }
  .page36{
    left: 14.5vw;
    top: 14vw;
    z-index: 3;
    width: 23vw;
    height: 2.9vw;
  }
  .page37{
    left: 14.5vw;
    top: 17.5vw;
    z-index: 3;
    width: 22.7vw;
    height: 2.85vw;
  }
  .page38{
    /* left: 420px;
    top: 180px; */
    width: inherit;
    height: inherit;
  }
  .page39{
    left: 20vw;
    top: 1.5vw;
    z-index: 3;
    width: 8.3vw;
    height: 5.7vw;
  }
  #page4 .person{
    position: absolute;
    width: 36vw;
    height: 42.5vw;
    left: 0px;
    right: 0;
    bottom: 0px;
    margin: auto;
    /* transform: scale(.9); */

  }
  .page41{
    left: 15vw;
    bottom: 20.5vw;
    width: 10.85vw;
    height: 3.9vw;
  }
  .page42{
    left: 15vw;
    bottom: 10.5vw;
    width: 10.75vw;
    height: 3.9vw;
  }
  .page43{
    left: 25vw;
    bottom: 15.5vw;
    width: 10.8vw;
    height: 3.75vw;
  }
  .page44{
    left: 12.5vw;
    top: -2vw;
    width: 28.55vw;
    height: 17.15vw;
  }
  .page45{
    width: inherit;
    height: inherit;
  }
  #page5 {
    /* padding-bottom: 110px; */
    /* box-sizing: border-box; */
    /* height: hcalc(100% + 110px); */

    /* overflow: scroll; */
  }
  #page5 .person{
    position: absolute;
    width: 25vw;
    height: 36vw;
    left: 0px;
    right: 0;
    bottom: 7vw;
    margin: auto;
    transform: scale(.9);
  }
  .page51{
    top: -6vw;
    left: 10.5vw;
    width: 26.95vw;
    height: 9.4vw;
  }
  .page52{
    top: 0px;
    left: -1.5vw;
    width: 9.3vw;
    height: 5.8vw;
  }
  .page53{
    top: 14vw;
    left: 25.5vw;
    width: 8.55vw;
    height: 2.85vw;
  }
  .page54{
    top: 24vw;
    left: 20.5vw;
    width: 11.8vw;
    height: 2.45vw;
  }
  .page55{
    top: 4vw;
    left: 20.5vw;
    width: 8.75vw;
    height: 2.7vw;
  }
  .page56{
    width: inherit;
    height: inherit;
  }
  .bot{
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(./assets/end-bg.png) no-repeat center center;
    background-size: cover;
    height: 110px;
    width: 100vw;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  .bot>div{
    width: 100vw;
    text-align: center;
    cursor: pointer;
  }
  .bot>div>a{
    color: #ffffff;
    text-decoration: none;
  }

  @media (max-width: 1600px) {
    #page1 .person{
      transform: scale(1.2); 
      bottom: 5vw;
    }
    #page2 .person{
      transform: scale(1.2); 
      bottom: 5vw;
    }
    #page3 .person{
      transform: scale(1.2); 
      bottom: 5vw;
    }
    #page4 .person{
      transform: scale(1.2); 
      bottom: 5vw;
    }
    #page5 .person{
      transform: scale(1.2); 
      bottom: 14vw;
    }
    
  }
  @media (max-width: 1480px) {
    .nav{
      padding-right: 100px;
    }
    .nav img{
      margin: 0 20px;
    }
  }
  @media (max-width: 1200px) {
    .nav{
      padding-right: 30px;
    }
    .nav img{
      margin: 0 10px;
    }
  }
</style>
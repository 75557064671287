/*
 * @Author: kincaid
 * @Date: 2022-05-21 16:21:21
 * @LastEditors: kincaid
 * @LastEditTime: 2022-05-22 10:12:13
 * @Description: file content
 */
import { createApp } from 'vue'
import App from './App.vue'
import 'animate.css';

createApp(App).mount('#app')
